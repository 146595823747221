export default[
    // "Work from home",     
    "Adityapur,Jharkhand",
    "Adoni,Andhra Pradesh",
    "Agartala,Tripura",
     "Agra,Uttar Pradesh", 
    "Ahmedabad,Gujarat", 
    "Ahmednagar,Maharashtra", 
    "Aizawl,Mizoram", 
    "Ajmer,Rajasthan", 
    "Akola,Maharashtra", 
    "Alappuzha,Kerala", 
    "Aligarh,Uttar Pradesh", 
    "Allahabad,Uttar Pradesh", 
    "Alwar,Rajasthan",
    "Amravati,Maharashtra", 
    "Amritsar,Punjab", 
    "Amroha,Uttar Pradesh", 
    "Anand,Gujarat",
    "Anantapur,Andhra Pradesh", 
    "Arrah,Bihar", 
    "Asansol,West Bengal", 
    "Bahadurgarh,Haryana",
    "Baharampur,West Bengal", 
    "Bahraich,Uttar Pradesh", 
    "Ballari,Karnataka", 
    "Balurghat,West Bengal", 
    "Bankura,West Bengal", 
    "Baramula,Jammu and Kashmir", 
    "Barmer,Rajasthan", 
    "Batala,Punjab", 
    "Bathinda,Punjab", 
    "Begusarai,Bihar", 
    "Belagavi,Karnataka", 
    "Bengaluru,Karnataka", 
    "Bettiah,Bihar", 
    "Bhagalpur,Bihar", 
    "Bharatpur,Rajasthan",
    "Bharuch,Gujarat", 
    "Bhavnagar,Gujarat", 
    "Bhilai Nagar,Chhattisgarh", 
    "Bhilwara,Rajasthan",
    "Bhimavaram,Andhra Pradesh", 
    "Bhiwandi,Maharashtra",
    "Bhiwani,Haryana", 
    "Bhopal,Madhya Pradesh", 
    "Bhubaneswar,Odisha", 
    "Bhuj,Gujarat", 
    "Bikaner,Rajasthan", 
    "Bilaspur,Chhattisgarh", 
    "Bokaro Steel City,Jharkhand", 
    "Brahmapur,Odisha", 
    "Chandigarh,Chandigarh",
    "Chennai,Tamil Nadu", 
    "Chhapra,Bihar",
    "Chittoor,Andhra Pradesh", 
    "Coimbatore,Tamil Nadu", 
    "Cuttack,Odisha", 
    "Darbhanga,Bihar", 
    "Davanagere,Karnataka", 
    "Dehradun,Uttarakhand", 
    "Dehri-on-Sone,Bihar", 
    "Delhi,Delhi", 
    "Deoghar,Jharkhand", 
    "Dhanbad,Jharkhand", 
    "Dhule,Maharashtra", 
    "Dibrugarh,Assam", 
    "Durg,Chhattisgarh",
    "Eluru,Andhra Pradesh", 
    "English Bazar,West Bengal", 
    "Erode,Tamil Nadu", 
    "Etawah,Uttar Pradesh", 
    "Faridabad,Haryana", 
    "Firozabad,Uttar Pradesh", 
    "Ganjbasoda,Madhya Pradesh", 
    "Gaya,Bihar",
    "Godhra,Gujarat", 
    "Guntur,Andhra Pradesh",
    "Gurgaon,Haryana",
    "Guwahati,Assam", 
    "Gwalior,Madhya Pradesh", 
    "Habra,West Bengal", 
    "Hajipur,Bihar",
    "Haldwani-cum-Kathgodam,Uttarakhand", 
    "Hapur,Uttar Pradesh", 
    "Hardwar,Uttarakhand", 
    "Hazaribag,Jharkhand", 
    "Hindupur,Andhra Pradesh", 
    "Hisar,Haryana", 
    "Hoshiarpur,Punjab", 
    "Hubli-Dharwad,Karnataka", 
    "Hugli-Chinsurah,West Bengal", 
    "Hyderabad,Telangana", 
    "Ichalkaranji,Maharashtra", 
    "Imphal,Manipur", 
    "Indore,Madhya Pradesh", 
    "Jabalpur,Madhya Pradesh", 
    "Jaipur,Rajasthan", 
    "Jalandhar,Punjab", 
    "Jalpaiguri,West Bengal", 
    "Jammu,Jammu and Kashmir", 
    "Jamnagar,Gujarat", 
    "Jamshedpur,Jharkhand",
    "Jhansi,Uttar Pradesh", 
    "Jind,Haryana",
    "Jodhpur,Rajasthan", 
    "Kadapa,Andhra Pradesh", 
    "Kaithal,Haryana",
    "Kakinada,Andhra Pradesh", 
    "Kalyan-Dombivali,Maharashtra", 
    "Kancheepuram,Tamil Nadu", 
    "Kanpur,Uttar Pradesh", 
    "Karimnagar,Telangana", 
    "Karnal,Haryana", 
    "Katihar,Bihar", 
    "Khammam,Telangana", 
    "Kharagpur,West Bengal", 
    "Kochi,Kerala", 
    "Kolar,Karnataka", 
    "Kolkata,West Bengal", 
    "Kollam,Kerala",
    "Korba,Chhattisgarh", 
    "Kozhikode,Kerala", 
    "Kurnool,Andhra Pradesh", 
    "Lakhimpur,Uttar Pradesh", 
    "Lalitpur,Uttar Pradesh", 
    "Latur,Maharashtra", 
    "Loni,Uttar Pradesh", 
    "Lucknow,Uttar Pradesh", 
    "Ludhiana,Punjab", 
    "Machilipatnam,Andhra Pradesh", 
    "Madanapalle,Andhra Pradesh", 
    "Madurai,Tamil Nadu", 
    "Mahbubnagar,Telangana", 
    "Mahesana,Gujarat", 
    "Malegaon,Maharashtra", 
    "Malerkotla,Punjab", 
    "Mandsaur,Madhya Pradesh", 
    "Mandya,Karnataka", 
    "Mangaluru,Karnataka", 
    "Medinipur,West Bengal", 
    "Meerut,Uttar Pradesh", 
    "Mira-Bhayandar,Maharashtra", 
    "Modinagar,Uttar Pradesh",
    "Moga,Punjab", 
    "Moradabad,Uttar Pradesh", 
    "Morena,Madhya Pradesh", 
    "Morvi,Gujarat", 
    "Mumbai,Maharashtra", 
    "Munger,Bihar", 
    "Murwara (Katni),Madhya Pradesh", 
    "Muzaffarpur,Bihar", 
    "Mysore,Karnatka", 
    "Nadiad,Gujarat", 
    "Nagercoil,Tamil Nadu", 
    "Nagpur,Maharashtra", 
    "Naihati,West Bengal", 
    "Nanded-Waghala,Maharashtra", 
    "Nandyal,Andhra Pradesh", 
    "Nashik,Maharashtra", 
    "Navsari,Gujarat", 
    "Nellore,Andhra Pradesh", 
    "New Delhi,Delhi", 
    "Nizamabad,Telangana", 
    "Noida,Uttar Pradesh", 
    "Ongole,Andhra Pradesh", 
    "Orai,Uttar Pradesh", 
    "Palakkad,Kerala", 
    "Pali,Rajasthan", 
    "Panchkula,Haryana", 
    "Panipat,Haryana", 
    "Panvel,Maharashtra", 
    "Parbhani,Maharashtra", 
    "Pathankot,Punjab", 
    "Patiala,Punjab", 
    "Patna,Bihar", 
    "Phusro,Jharkhand", 
    "Pollachi,Tamil Nadu", 
    "Pondicherry,Puducherry", 
    "Porbandar,Gujarat", 
    "Proddatur,Andhra Pradesh", 
    "Pune,Maharashtra", 
    "Puri,Odisha", 
    "Purnia,Bihar", 
    "Raayachuru,Karnataka",
    "Rae Bareli,Uttar Pradesh", 
    "Raghunathganj,West Bengal", 
    "Raiganj,West Bengal",
    "Raipur,Chhattisgarh", 
    "Rajahmundry,Andhra Pradesh", 
    "Rajkot,Gujarat", 
    "Rajnandgaon,Chhattisgarh", 
    "Ramagundam,Telangana", 
    "Rampur,Uttar Pradesh", 
    "Ranaghat,West Bengal", 
    "Ranchi,Jharkhand", 
    "Ranipet,Tamil Nadu", 
    "Ratlam,Madhya Pradesh", 
    "Raurkela,Odisha", 
    "Rewa,Madhya Pradesh",
    "Robertson Pet,Karnataka", 
    "Rohtak,Haryana", 
    "Sagar,Madhya Pradesh", 
    "Saharanpur,Uttar Pradesh", 
    "Saharsa,Bihar", 
    "Salem,Tamil Nadu", 
    "Sambalpur,Odisha", 
    "Sambhal,Uttar Pradesh", 
    "Sangli,Maharashtra", 
    "Santipur,West Bengal", 
    "Sasaram,Bihar", 
    "Satna,Madhya Pradesh", 
    "Shahjahanpur,Uttar Pradesh", 
    "Shillong,Meghalaya", 
    "Shimla,Himachal Pradesh", 
    "Shivamogga,Karnataka", 
    "Shivpuri,Madhya Pradesh", 
    "Sikar,Rajasthan", 
    "Silchar,Assam", 
    "Siliguri,West Bengal", 
    "Singrauli,Madhya Pradesh", 
    "Sirsa,Haryana", 
    "Sitapur,Uttar Pradesh", 
    "Solapur,Maharashtra", 
    "Sonipat,Haryana", 
    "Srinagar,Jammu and Kashmir",
    "Srinagar,Uttarakhand", 
    "Surat,Gujarat",
    "Tenali,Andhra Pradesh", 
    "Thane,Maharashtra",
    "Thanesar,Haryana", 
    "Thanjavur,Tamil Nadu", 
    "Thiruvananthapuram,Kerala",
    "Thrissur,Kerala", 
    "Tiruchirappalli,Tamil Nadu", 
    "Tirunelveli,Tamil Nadu", 
    "Tirupati,Andhra Pradesh", 
    "Tiruppur,Tamil Nadu", 
    "Tiruvannamalai,Tamil Nadu", 
    "Tonk,Rajasthan",
    "Tumkur,Karnataka", 
    "Udaipur,Rajasthan", 
    "Ujjain,Madhya Pradesh", 
    "Unnao,Uttar Pradesh", 
    "Vadodara,Gujarat", 
    "Vapi,Gujarat", 
    "Varanasi,Uttar Pradesh", 
    "Vasai-Virar,Maharashtra", 
    "Vellore,Tamil Nadu", 
    "Veraval,Gujarat", 
    "Vidisha,Madhya Pradesh", 
    "Vijayawada,Andhra Pradesh", 
    "Visakhapatnam,Andhra Pradesh", 
    "Vizianagaram,Andhra Pradesh", 
    "Warangal,Telangana", 
    "Yamunanagar,Haryana", 
    
    ]
    