<template>
  <b-card>
    <!-- form -->
    <!-- <b-alert show variant="primary">
      <div class="alert-body d-flex align-middle items-center justify-content-center">
        <div class="pt-25">
          <u-icon class="mr-25" icon="fi-rr-exclamation" />
        </div>
        <small class=" font-small-4 ml-25">Introduce yourself to job seekers. Information you might want to include:
          when and why your business was founded, how much you've grown since, what your campus does and the types of
          customers you serve, and your mission statement.
        </small>
      </div>
    </b-alert> -->

    <validation-observer ref="campusInfoForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveCampusInfo">
        <b-row>
          <!-- Select Campus -->
          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="campus name" vid="campus-name" rules="required">
                <label> Campus Name<label class="text-danger">*</label></label>
                <b-form-input name="campus name" v-model="campusName" placeholder="Institute of Technology" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ select Campus -->
          <!-- bio -->
          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="campus description" vid="campus-description"
                rules="required">

                <label> Campus Description<label class="text-danger">*</label></label>

                <b-form-textarea v-model="campusDesc" rows="4" placeholder="About your campus here..."
                  name="campus description" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- Location -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="campus location" vid="campus-location" rules="required">

                <label> Campus Location<label class="text-danger">*</label></label>
                <v-select v-model="campuslocation" name="campus location"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="locationOptions" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Location  -->

          <!-- recognised -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="campus recognised" vid="campus-recognised"
                rules="required">

                <label> Approved by/ Recognised by<label class="text-danger">*</label></label>
                <v-select v-model="campusRecognised" name="campus recognised"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" multiple
                  :options="campusRecognisedList" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ recognised -->



          <!-- website -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="campus website" vid="campus-website" rules="required">

                <label> Campus Website<label class="text-danger">*</label></label>
                <b-form-input name="campus website" v-model="campusWeb" placeholder="Website address" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ website -->

          <!-- totalStudents -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="total students" vid="total-students" rules="numeric">
                <label class="mb-50"> Total Students <label> </label></label>
                <b-form-input name="total students" v-model="totalStudents" placeholder="2000" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- totalStudents -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit"
              :disabled="invalid">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1" variant="outline-secondary"
              @click.prevent="resetForm">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BAvatar, BFormInput, BInputGroup, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormTextarea

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from "@core/utils/filter";
import cityOptions from '../../recruiterpages/cityOptions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import firebase from "firebase/app";
import "firebase/storage";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric, min, max } from '@validations'
import store from "@/store";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BInputGroup,
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    BAvatar,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    VueAutosuggest
  },
  directives: {
    Ripple,
  },
  props: {

  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      // localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      campusName: '',
      campusDesc: '',
      campusRecognised: [],
      campuslocation: '',
      campusSize: '',
      campusType: '',
      campusWeb: '',
      totalStudents: '',
      // recruiterCode: store.state.mainUserCodes.RecruiterCode,
      selectedRecruiterCode: '',
      selectedCampusObject: {},
      locationOptions: cityOptions,
      campusRecognisedList: [
        'All India Council of Technical Education (AICTE)',
        'Bar Council of India (BCI)',
        'Medical Council of India (MCI)',
        'Indian Council for Agricultural Research (ICAR)',
        'National Council for Teacher Education (NCTE)',
        'Dental Council of India (DCI)',
        'Pharmacy Council of India (PCI)',
        'Indian Nursing Council (INC)',
        'Central Council of Homeopathy (CCH)',
        'Central Council for Indian Medicine (CCIM)',
        'Council of Architecture (COA)',
        'Distance Education Council (DEC)',
        'Rehabilitation Council',
        'National Council for Rural Institutes (NCRI)',
        'State Council of Higher Education (SCHE)'
      ],
      sizeList: ["Below 10", "10-50", "50-100", "100-500", "Above 500"],
      required,
      email,
      numeric,
      min,
      max,
      recruiterList: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Enter your Campus Name",
        name: "campus name"
      },
      limit: 10,
      filteredCompanies: [],
      rCodesList: []


    }
  },
  created() {
    const thisIns = this
    thisIns.getFirebaseData()
    // console.log(store.state.mainUserCodes)

  },

  methods: {
    getFirebaseData() {
      const thisIns = this
      // console.log(thisIns.recruiterCode)
      var collegeCode = this.$store.state.mainUserCodes.CollegeCode
      firebase.firestore().collection("CollegeCode").doc(collegeCode).get().then((doc) => {
        var obj = doc.data()
        obj.name = doc.data().CollegeName
        obj.code = doc.id
        thisIns.selectedCampusObject = obj
        thisIns.selectedRecruiterCode = obj.code
        thisIns.setData()
      })

    },
    setData() {
      this.campusName = this.selectedCampusObject.CollegeName
      this.campusDesc = this.selectedCampusObject.description
      this.campuslocation = this.selectedCampusObject.CampusLocation
      this.campusWeb = this.selectedCampusObject.website
      this.campusRecognised = this.selectedCampusObject.RecognisedBy
      // this.campusType = this.selectedCampusObject.CampusType
      // this.campusSize = this.selectedCampusObject.CampusSize
      this.totalStudents = this.selectedCampusObject.T_Students ?? ''
    },


    resetForm() {
      // this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    saveCampusInfo() {
      this.$refs.campusInfoForm.validate().then(async success => {
        if (success) {
          var user = firebase.auth().currentUser;
          // console.log(`this is selected code ${this.selectedRecruiterCode}`)
          var collegeCode = this.$store.state.mainUserCodes.CollegeCode

          firebase.firestore().collection("CollegeCode").doc(collegeCode).set({
            CollegeName: this.campusName,
            description: this.campusDesc,
            CampusLocation: this.campuslocation,
            website: this.campusWeb,
            RecognisedBy: this.campusRecognised,
            T_Students: this.totalStudents,
          },{merge:true}).then(() => {
            // console.log('updated data')
            // }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully Updated',
                icon: 'fi-rr-exclamation',
                text: '👋 Campus details are updated successfully.',
                variant: 'success',
              },
            })

          })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong!',
                  icon: 'fi-rr-exclamation',
                  text: `${err.message}`,
                  variant: 'danger',
                },
              })
            });
        }

      })
    },

    avatarText
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
