var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"campusInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.saveCampusInfo($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"campus name","vid":"campus-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Campus Name"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"campus name","placeholder":"Institute of Technology"},model:{value:(_vm.campusName),callback:function ($$v) {_vm.campusName=$$v},expression:"campusName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"campus description","vid":"campus-description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Campus Description"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-textarea',{attrs:{"rows":"4","placeholder":"About your campus here...","name":"campus description"},model:{value:(_vm.campusDesc),callback:function ($$v) {_vm.campusDesc=$$v},expression:"campusDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"campus location","vid":"campus-location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Campus Location"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"campus location","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.locationOptions},model:{value:(_vm.campuslocation),callback:function ($$v) {_vm.campuslocation=$$v},expression:"campuslocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"campus recognised","vid":"campus-recognised","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Approved by/ Recognised by"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"campus recognised","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","options":_vm.campusRecognisedList},model:{value:(_vm.campusRecognised),callback:function ($$v) {_vm.campusRecognised=$$v},expression:"campusRecognised"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"campus website","vid":"campus-website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Campus Website"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"campus website","placeholder":"Website address"},model:{value:(_vm.campusWeb),callback:function ($$v) {_vm.campusWeb=$$v},expression:"campusWeb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"total students","vid":"total-students","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-50"},[_vm._v(" Total Students "),_c('label')]),_c('b-form-input',{attrs:{"name":"total students","placeholder":"2000"},model:{value:(_vm.totalStudents),callback:function ($$v) {_vm.totalStudents=$$v},expression:"totalStudents"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Save changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }