<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <!-- <b-img ref="previewEl" rounded :src="avatar ? avatar : require('@/assets/images/avatars/1-small.png')"
            height="80" /> -->
          <b-avatar ref="previewEl" size="80" square :src="avatar" variant="transparent"
            class=" text-secondary avatar-border-2" style="border-radius: 16px !important;">
            <u-icon v-if="!avatar" icon="fi-rr-user" size="22" />
          </b-avatar>
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()">
          Upload
        </b-button>
        <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
          @input="inputImageRenderer" />
        <!--/ upload button -->

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text class="text-secondary font-small-3">Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="generalForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveGeneralData">
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="name" vid="name" rules="required">

                <label> Full Name<label class="text-danger">*</label></label>
                <b-form-input v-model="fullName" name="name" placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="email" vid="email" rules="required|email">

                <label> Work E-mail Address<label class="text-danger">*</label></label>

                <b-form-input v-model="email" name="email" placeholder="xyz@companyname.com" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="phone" vid="phone"
                rules="required|numeric|min:10|max:10">

                <label>Mobile Number<label class="text-danger">*</label></label>
                <b-input-group prepend="+91">

                  <b-form-input v-model="phone" name="phone" placeholder="9876543210"  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="designation" vid="designation" rules="required">

                <label>Designation<label class="text-danger">*</label></label>
                <b-form-input v-model="designation" name="designation" placeholder="Sr. HR Manager" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!-- <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col> -->
          <!--/ alert -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" type="submit"
              :disabled="invalid">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
              @click.prevent="resetForm">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BAvatar, BFormInput, BInputGroup, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import firebase from "firebase/app";
import "firebase/storage";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric, min, max } from '@validations'
import store from "@/store";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BInputGroup,
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    BAvatar
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      fullName: '',
      email: '',
      phone: '',
      designation: '',
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      uploadedUrl: '',
      avatar: '',
      rawFile: null,
      required,
      email,
      numeric,
      min,
      max
    }
  },
  watch: {
    generalData() {
      this.setAllFields()
    }
  },
  created() {
    // if(this.generalData){
    this.setAllFields()
    // }
  },
  methods: {
    inputImageRenderer() {
      console.log('here rendering')
      // var refInputEl = this.$refs.refInputEl
      // console.log(this.$refs.refInputEl)
      // var previewEl = this.$refs.previewEl
      const file = this.refInputEl.files[0]
      this.rawFile = file;
      const reader = new FileReader()
      // console.log(this.$refs.previewEl)
      reader.addEventListener(
        'load',
        () => {
          this.previewEl.localSrc = reader.result
          this.avatar = reader.result
          // console.log(this.previewEl)
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    setAllFields() {
      this.fullName = this.generalData.ContactName || this.generalData.FullName,
        this.email = this.generalData.email
      this.designation = this.generalData.Designation
      this.phone = this.generalData.ContactNo || this.generalData.initialsavedNo
      this.avatar = this.generalData.photo
      this.uploadedUrl = this.generalData.photo ?? ''
    },
    resetForm() {
      this.fullName = this.generalData.ContactName || this.generalData.FullName,
        this.email = this.generalData.email
      this.designation = this.generalData.Designation
      this.phone = this.generalData.ContactNo
      this.avatar = this.generalData.photo
    },
    async uploadFile(rawFile) {

      // let uid = store.state.mainUserCodes.user_id
      var uid = firebase.auth().currentUser.uid
      // console.log(uid)
      var result = await new Promise(async (resolve) => {
        var storageRef = {}
        storageRef = firebase.storage().ref(`TPO/profilephoto/` + uid + rawFile.name);
        const thisIns = this
        await storageRef.put(rawFile).then(function (result) {
          //  console.log(result)
          storageRef.getDownloadURL().then(function (url) {
            var img = url;
            // console.log(url)
            thisIns.uploadedUrl = img
            resolve(thisIns.uploadedUrl)

          })

        });
      })
      return result;
    },
    async saveGeneralData() {
      this.$refs.generalForm.validate().then(async success => {
        if (success) {
          // console.log(this.$refs.previewEl)
          var user = firebase.auth().currentUser
          if (this.rawFile) {
            await this.uploadFile(this.rawFile)
          }
          // console.log(this.avatar)
          var objSaved = {
            email: this.email,
            FullName: this.fullName,
                        ContactName: this.fullName,
                        username: this.fullName,
            ContactNo: this.phone,
            Designation: this.designation,
            photo: this.uploadedUrl,
            personalDetails: true,
          }
          if(typeof (this.generalData.initialsavedNo) == 'undefined' ){
            objSaved.initialsavedNo = this.phone
          }
          firebase.firestore().collection("TPOid").doc(user.uid).set(objSaved, { merge: true }).then(() => {
            // if(this.rawFile){
            store.dispatch("auth/updateUserInfo", {
              username: this.fullName,
              displayName: this.fullName,
              profilephoto: this.uploadedUrl,
              avatar: this.uploadedUrl,
            });
            console.log('updated data')
            // }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully Updated',
                icon: 'fi-rr-exclamation',
                text: '👋 General details are updated successfully.',
                variant: 'success',
              },
            })

          }).catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                icon: 'fi-rr-exclamation',
                text: `${err.message}`,
                variant: 'danger',
              },
            })
          });
        }
      })
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const getUserPic = (res) => {
      previewEl.value = res
      console.log(previewEl.value)
    }
    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, getUserPic)

    return {
      refInputEl,
      previewEl,
      // inputImageRenderer,
    }
  },
}
</script>
<style>
.form-control:disabled{
  cursor: not-allowed;
}
</style>
